
  
//   document.onreadystatechange = function() {
//         if (document.readyState !== "complete") {
//             document.querySelector(
//               "body").style.visibility = "hidden";
//             document.querySelector(
//               "#loader").style.visibility = "visible";
//         } else {
//             document.querySelector(
//               "#loader").style.display = "none";
//             document.querySelector(
//               "body").style.visibility = "visible";
//         }
//     };
//   $bodye = $("body");
//   $bodye.addClass("loading");

let deleteflag = true;
        let table;
        let currentrow=-1;
        let deleterow=-1;

        const alpVal=['A','B','C','D','E','F','G']

        function settype(type){
            console.log("test:::::",type,currentrow)
            if(type == "close" )
            {
                console.log("aa");
                location.reload();
            }
            setTimeout(() => {
                let flag=false;
               table.setValueFromCoords(6,currentrow+1,type);
               if(type == 'CP'){
                        let row=table.getRowData(currentrow)
                        let previoustype=row[6]
                        if(previoustype != "Station")
                        {
                            let sum =`ROUND(${alpVal[4]}${currentrow+1} - ${alpVal[3]}${currentrow+2},2)`
                            console.log("summmm",sum)

                            table.setValueFromCoords(5,currentrow+1,`=ROUND(${alpVal[4]}${currentrow+1} - ${alpVal[3]}${currentrow+2},2)`,true)
                            table.setValueFromCoords(4,currentrow+1,`=ROUND(${alpVal[1]}${currentrow+2} + ${alpVal[5]}${currentrow+2},2) `,true)
                        }
                        else if(previoustype == "CP")
                        {


                            table.setValueFromCoords(4,currentrow+1,`=ROUND(${alpVal[1]}${currentrow+2} + ${alpVal[5]}${currentrow+2},2) `,true)
                        }
                        else{
                            flag=true;
                            alert("previous type is Station don't allow")
                        }
                    }
                    else if(type =='Station'){

                        let row=table.getRowData(currentrow)
                        let previoustype=row[6]
                        if(previoustype != "Station"){


                            table.setValueFromCoords(4,currentrow+1,`=${alpVal[4]}${currentrow+1}`,true)
                            table.insertRow(9, currentrow+1);

                          const me=currentrow+1

                            for(var j =0;j<9;j++)
                            {
                                console.log('loop',j+me)
                                table.setValueFromCoords(6,j+me,'Point');
                                table.setValueFromCoords(4,j+me,`=${alpVal[4]}${j+me}`,true)
                        table.setValueFromCoords(5,j+me,`=ROUND(${alpVal[4]}${j+me+1} - ${alpVal[2]}${j+me+1},2) `,true)
                                // me++;
                            }
                        }
                        else{
                            flag=true;
                            alert("previous type is Station don't allow")
                        }


                    }
                    else if(type == 'Point'){

                        let row=table.getRowData(currentrow)
                        let previoustype=row[6]
                        if(previoustype != "CP")
                        {
                        table.setValueFromCoords(4,currentrow+1,`=${alpVal[4]}${currentrow+1}`,true)
                        table.setValueFromCoords(5,currentrow+1,`=ROUND(${alpVal[4]}${currentrow+2} - ${alpVal[2]}${currentrow+2},2) `,true)
                        }
                        else{
                            flag=true;
                            alert("previous type is CP don't allow")

                        }
                }
                $('#inputModal').modal('hide')

               if(flag){
                let previoustype=table.getValue(`G${currentrow}`)
                     rownumber=currentrow +1
                    console.log("deleting row ")
                    deleteflag=false;

                    console.log("flagg true ",deleteflag)
                    table.deleteRow(rownumber);
               }
               else{
                   console.log("else")
                        let insertindes=currentrow+1;
                        console.log("inserttt",insertindes)
                        let cType=table.getValue(`G${insertindes+1}`)
                        console.log("ctype",cType);
                    while(cType != "" && cType != null)
                    {
                        console.log("while")

                        if(cType=='CP'){

                            table.setValueFromCoords(5,insertindes+1,`=ROUND(${alpVal[4]}${insertindes+1} - ${alpVal[3]}${insertindes+2},2)`,true)
                            table.setValueFromCoords(4,insertindes+1,`=ROUND(${alpVal[1]}${insertindes+2} + ${alpVal[5]}${insertindes+2},2) `,true)
                            }
                            else if(cType=='Station'){
                                console.log("indexxxxx",currentrow)
                                let selectedrow=table.getRowData(currentrow)
                                console.log(selectedrow)
                                console.log("inser -indexxxxx ma add 1 kar rha ya wo wla index",insertindes)
                            table.setValueFromCoords(4,insertindes+1,`=${alpVal[4]}${insertindes+1}`,true)

                            }
                            else if(cType == 'Point'){
                            table.setValueFromCoords(4,insertindes+1,`=${alpVal[4]}${insertindes+1}`,true)
                            table.setValueFromCoords(5,insertindes+1,`=ROUND(${alpVal[4]}${insertindes+2} - ${alpVal[2]}${insertindes+2},2) `,true)
                            }
                            insertindes++;
                        cType=table.getValue(`G${insertindes+2}`)
                    }
               }
       }, 500);
}

        var id= document.getElementById('textbox_id').value

        var url = '{{ route("sheet") }}';
       // url = url.replace(':id', id );

        $.ajax({
           type:'post',
           url:url,
           data:{
            id:id,
            _token: '{{csrf_token()}}'
           },
           success:function(response) {
             
               data=response[0];
               projectname=response[1]
            render(data,projectname)
           }
        });

        function render(data,projectname)
        {
     

            let previousdata=data;


         function checkIfStringHasOnlyDigits(_string)
        {
            if(_string.match(/^[0-9]+$/) != null)
            {
                return true;
            }
            else
            {
                const check = parseFloat(_string)
                if(check){
                    return true;
                }
                return false;
            }
        }

            var beforeChange = function(instance, cell, x, y, value) {
                const type = data[y][6];
                if(type === 'CP' )
                {

                    if(y == 0 )
                    {

                        if(x==0 && value === "" )
                        {
                            const preValue = previousdata[y][x]

                        alert("you can not do this from cp");
                        setTimeout(() => {

                                table.setValueFromCoords(x,y,preValue);

                        }, 500);

                        }
                        else if( (x== 1 || x== 5) && (value === '' || !checkIfStringHasOnlyDigits(value))  ){
                            const preValue = previousdata[y][x]

                        alert("you can not do this from cp");
                        setTimeout(() => {
                                console.log('prev',preValue)
                                table.setValueFromCoords(x,y,preValue)

                        }, 500);

                        }
                        else{

                            if(x == 1 || x == 5){

                            // console.log("hereeee")

                                // setTimeout(() => {
                                //     const size = data.length
                                // //     const bs= parseFloat(table.getValueFromCoords(1,y));
                                // // const rl =parseFloat(table.getValueFromCoords(5,y));
                                // // let hi=(bs + rl).toString();

                                // // console.log(bs,rl,hi)
                                // //     console.log("set time out")
                                // console.log('val',`=${alpVal[1]}${y+1} + ${alpVal[5]}${y+1}`)
                                //     table.setValueFromCoords(4,0,`=${alpVal[1]}${y+1} + ${alpVal[5]}${y+1}`)
                                //     // previousdata[y][4]=hi
                                //     // for(let i =y+1;i<size)

                                // }, 500);



                                // previousdata[y][4]=hi
                            }
                            previousdata[y][x]=value
                            // console.log(previousdata)
                        }
                    }

                    else{
                        if(x==0 && value === "" )
                        {
                            const preValue = previousdata[y][x]

                        alert("you can not do this from cp");
                        setTimeout(() => {

                                table.setValueFromCoords(x,y,preValue)

                        }, 500);
                        }
                        else if( (x== 1 ||x==3) && (value === '' || !checkIfStringHasOnlyDigits(value))  ){
                            const preValue = previousdata[y][x]

                        alert("you can not do this from cp");
                        setTimeout(() => {

                                table.setValueFromCoords(x,y,preValue)

                        }, 500);

                        }
                        else{
                            if(x== 1 ||x==3){

                                // setTimeout(() => {
                                // const hi =parseFloat(table.getValueFromCoords(4,y-1));
                                // const fs= parseFloat(table.getValueFromCoords(3,y));
                                // const rl=(hi - fs);
                                // const bs= parseFloat(table.getValueFromCoords(1,y));
                                // const nHi = (bs + rl).toString()
                                // table.setValueFromCoords(5,y,rl.toString())
                                // table.setValueFromCoords(4,y,nHi)

                                // previousdata[y][5]=rl.toString()
                                // previousdata[y][4]=nHi


                                //     // console.log("set time out",rs)
                                // }, 500);

                            }
                            previousdata[y][x]=value
                        }

                        previousdata[y][x]=value

                    }

            }
            else if(type === 'Station'){

                    if(x==0 &&( value === "" || !checkIfStringHasOnlyDigits(value) ) )
                        {
                            const preValue = previousdata[y][x]

                        alert("you can not do this from Station");
                        setTimeout(() => {

                                table.setValueFromCoords(x,y,preValue)

                        }, 500);
                        }
                        else{
                            previousdata[y][x]=value
                        }
            }
            else if(type === 'Point')
            {
                if(x==0 &&( value === "" || !checkIfStringHasOnlyDigits(value) ) )
                        {
                            const preValue = previousdata[y][x]

                        alert("you can not do this from Point");
                        setTimeout(() => {

                                table.setValueFromCoords(x,y,preValue)

                        }, 500);
                        }
                        else if( x==2  &&( value === "" || !checkIfStringHasOnlyDigits(value) ) ){
                            const preValue = previousdata[y][x]

                        alert("you can not do this from Point");
                        setTimeout(() => {

                                table.setValueFromCoords(x,y,preValue)

                        }, 500);
                        }
                        else{
                            if(x == 2 ){
                            // console.log("hereeee")

                            //     setTimeout(() => {
                            //         const is= parseFloat(table.getValueFromCoords(2,y));
                            //     const hi =parseFloat(table.getValueFromCoords(4,y));
                            //     const rs=(hi - is).toString();

                            //         console.log("set time out",rs)
                            //         table.setValueFromCoords(5,y,rs)
                            //         previousdata[y][5]=rs
                            //     }, 500);



                                // previousdata[y][4]=hi
                            }

                            previousdata[y][x]=value
                        }


            }

            }
            var loaded = function(instance,value,x) {

              


               setTimeout(() => {
                data.forEach((item,index) => {
                    const type = item[6];
                    if(type=='CP' && index==0){
                        table.setValueFromCoords(4,0,`=ROUND(${alpVal[1]}${index+1} + ${alpVal[5]}${index+1},2)`,true)

                    }else if(type=='CP'){

                        table.setValueFromCoords(5,index,`=ROUND(${alpVal[4]}${index} - ${alpVal[3]}${index+1},2)`,true)
                        table.setValueFromCoords(4,index,`=ROUND(${alpVal[1]}${index+1} + ${alpVal[5]}${index+1},2) `,true)



                    }
                    else if(type=='Station'){
                        table.setValueFromCoords(4,index,`=${alpVal[4]}${index}`,true)

                    }
                    else if(type == 'Point'){
                        table.setValueFromCoords(4,index,`=${alpVal[4]}${index}`,true)
                        table.setValueFromCoords(5,index,`=ROUND(${alpVal[4]}${index+1} - ${alpVal[2]}${index+1},2) `,true)
                    }

                });
               }, 1000);
              // $bodye.removeClass("loading");
            }
            var insertedRow = function(instance,x,y) {
                currentrow=x;
                const length=previousdata.length;
                if(currentrow+2 != previousdata.length)
                {


                }
                $('#inputModal').modal('show')
            //    myModal.show();

                // const previousrow= previousdata[x];
                // const previoustype=previousrow[7];
                // console.log("xx",x,"yy",y)
                // setTimeout(() => {
                //                 //console.log('prev',preValue)
                //                 table.setValueFromCoords(x,6,'Cp',true)

                //         }, 500)

                // if(type  ==  'CP')
                // {
                    //  console.log(table.setValueFromCoords(x,6,"Cp"));

                // }
                // else if( type == 'Point'  ){
                //     if( previoustype == 'Station'  )
                //     {

                //     }
                //     else{
                //         setTimeout(() => {
                //                 table.undo()

                //         }, 500);
                //     }

                // }
                // else if(type == 'Point'){
                //     if( previoustype == ''  )
                //     {

                //     }
                //     else{
                //         setTimeout(() => {
                //                 table.undo()

                //         }, 500);
                //     }


                // }


                }
                var deleteRow = function(instance,x,y){
                    console.log("xx",x)

                    const length=previousdata.length;
                    let selectedrow=table.getRowData(x)
                    let selectedtype=selectedrow[6]
                    let previousrow=0;
                    let previoustype=""
                    if (x == 0)
                    {

                     previousrow = -1
                     previoustype= -1
                    }else{

                         previousrow=table.getRowData(x-1)
                          previoustype=previousrow[6]

                    }
                    let nexttype=""

                    if(x+1 == length )
                    {
                    let nexttype=""

                    }else{
                        let nextrow=table.getRowData(x+1)

                     nexttype=nextrow[6]

                    }
                    if(previoustype == "Point" && nexttype == "Point")
                    {
                    //    console.log("point point")
                       return true;

                    }
                    else if(selectedtype == "CP" && x!= 0)
                    {
                       return true;

                    }
                    else if(x == 0)
                    {
                        console.log("checkk first row")
                        alert("don't allow to delete a row")
                        return false;
                    }

                    else if(x+1 == length ){
                        return true

                    }
                    else if(selectedtype == 'Station' ){
                        alert("don't allow to delete a row")
                        return false;

                    }

                    else{
                        if(deleteflag==false){
                        console.log("calling by flag")
                        deleteflag=true;
                        return true;
                    }
                    }






                }

        
        
         table=jexcel(document.getElementById('spreadsheet'), {
            data,
            csvFileName:projectname,
             csvHeaders:true,
           copyCompatibility:true,
            columns: [
                {
                    type: 'text',
                    title:'STATION',
                    width:150
                },
                {
                    type: 'text',
                    title:'B.S',
                    width:150
                },
                {
                    type: 'text',
                    title:'I.S',
                    width:150
                },
                {
                    type: 'text',
                    title:'F.S',
                    width:150
                },
                {
                    type: 'text',
                    title:'H.I',
                    width:150,
                    readOnly:true
                },
                {
                    type: 'text',
                    title:'R.L',
                    width:150
                },
                {
                    type: 'hidden',
                },
                {
                    type: 'text',
                    title:'Remarks',
                    width:520
                },
            ],
            onbeforeinsertrow: insertedRow,
            onload: loaded,
            rowDrag:false,
            columnDrag:false,
           // fullscreen = true,
        //    tableOverflow:true,
        //    tableHeight: '100%',
        //     lazyLoading:true,
        //     loadingSpin:true,
           onbeforedeleterow:deleteRow,
            onbeforechange: beforeChange,
            contextMenu: function(obj, x, y, e)
        {
            var items = [];

            if (y == null) {
                // Insert a new column
                if (obj.options.allowInsertColumn == true) {
                    items.push({
                        title:obj.options.text.insertANewColumnBefore,
                        onclick:function() {
                            obj.insertColumn(1, parseInt(x), 1);
                        }
                    });
                }

                if (obj.options.allowInsertColumn == true) {
                    items.push({
                        title:obj.options.text.insertANewColumnAfter,
                        onclick:function() {
                            obj.insertColumn(1, parseInt(x), 0);
                        }
                    });
                }

                // Delete a column
                if (obj.options.allowDeleteColumn == true) {
                    items.push({
                        title:obj.options.text.deleteSelectedColumns,
                        onclick:function() {
                            obj.deleteColumn(obj.getSelectedColumns().length ? undefined : parseInt(x));
                        }
                    });
                }

                // Rename column
                if (obj.options.allowRenameColumn == true) {
                    items.push({
                        title:obj.options.text.renameThisColumn,
                        onclick:function() {
                            obj.setHeader(x);
                        }
                    });
                }

                // Sorting
                if (obj.options.columnSorting == true) {
                    // Line
                    items.push({ type:'line' });

                    items.push({
                        title:obj.options.text.orderAscending,
                        onclick:function() {
                            obj.orderBy(x, 0);
                        }
                    });
                    items.push({
                        title:obj.options.text.orderDescending,
                        onclick:function() {
                            obj.orderBy(x, 1);
                        }
                    });
                }
            } else {
                // Insert new row
                if (obj.options.allowInsertRow == true) {


                    items.push({
                        title:obj.options.text.insertANewRowAfter,
                        onclick:function() {
                            obj.insertRow(1, parseInt(y));
                            console.log("y",y)
                        }
                    });
                }

                if (obj.options.allowDeleteRow == true) {
                    items.push({
                        title:obj.options.text.deleteSelectedRows,
                        onclick:function() {
                            obj.deleteRow(obj.getSelectedRows().length ? undefined : parseInt(y));
                        }
                    });
                }

                if (x) {
                    if (obj.options.allowComments == true) {
                        items.push({ type:'line' });

                        var title = obj.obj.getCellFromCoords(x, y).getAttribute('title') || '';

                        items.push({
                            title: title ? obj.options.text.editComments : obj.options.text.addComments,
                            onclick:function() {
                                obj.setComments([ x, y ], prompt(obj.options.text.comments, title));
                            }
                        });

                        if (title) {
                            items.push({
                                title:obj.options.text.clearComments,
                                onclick:function() {
                                    obj.setComments([ x, y ], '');
                                }
                            });
                        }
                    }
                }
            }

            // Line
            items.push({ type:'line' });
            return items;
 },
            updateTable: function(el, cell, x, y, source, value, id) {

               // console.log("update")
                const type = data[y][6];

               if(type === 'CP' && y===0){
                   if(x===0){
                    cell.classList.add('CP');
                   }

                   else if(x===2){
                    cell.classList.add('readonly');
                    cell.classList.add('grey');
                   }
                   else if(x===3){
                    cell.classList.add('readonly');
                    cell.classList.add('grey');
                   }

               }
               else if(type === 'CP'){
                   if(x===0){
                    cell.classList.add('CP');
                   }

                   else if(x===2){
                    cell.classList.add('readonly');
                    cell.classList.add('grey');
                   }
                   else if(x===5){
                    cell.classList.add('readonly');

                   }

               }
               else if(type === 'Station'){
                 if(x===0){

                    cell.classList.add('station');
                   }
                   else if(x===1){
                    cell.classList.add('readonly');
                    cell.classList.add('grey');
                   }

                   else if(x===2){
                    cell.classList.add('readonly');
                    cell.classList.add('grey');
                   }
                   else if(x===3){
                    cell.classList.add('readonly');
                    cell.classList.add('grey');
                   }
                   else if(x===5){
                    cell.classList.add('readonly');
                    cell.classList.add('grey');
                   }

               }
               else if(type === 'Point'){
                if(x===0){

                    cell.classList.add('point');
                   }
               else if(x===1){
                    cell.classList.add('readonly');
                    cell.classList.add('grey');
                   }
                  else if(x===3){
                    cell.classList.add('readonly');
                    cell.classList.add('grey');
                   }
                   else if(x===5){
                    cell.classList.add('readonly');

                   }

               }

            }

        });
   

        $("#file-btn").click(function () {
            var sheetdata = $('#spreadsheet').jexcel('getData');
            let excelsheet=sheetdata;
            arr=['','','','','','','',''];
            sheetdata.push(arr)

            const length=sheetdata.length;
           // console.log("data",sheetdata)
          //   console.log("length",length)
        let data_array=[];
        let block = {};
        if (length > 3)   {

        sheetdata.forEach((d, index)=>{

            if (d[6] === 'Station') {
                    if (block.Station) {
                        console.log('here', block);
                        if (block.Point.length === 1) {
                            let pt = { 'x': '-10', 'y': block.Point[0].y };
                            block.Point.unshift(pt);
                        }
                        else if (block.Point.length > 1) {
                            console.log('here::::');
                            // let neg = false;
                            if (block.Point[0].x !== '0') {

                                let newPoint = block.Point.map((p) => {
                                    if (p.x === '0') {
                                        block.neg = false;
                                    }
                                    if (block.neg) {
                                        return { 'x': '-' + p.x, 'y': p.y };
                                    }
                                    else {
                                        return { 'x': p.x, 'y': p.y };
                                    }
                                });
                                block.Point = newPoint;
                            }


                        }

                        data_array.push(block);
                    }
                    block = { 'Station': '', 'Point': [], neg: false };
                    block.Station = d[0].trim();
                }
                else if (d[6] === 'Point') {
                    if (d[0].trim() === '0') {
                        block.neg = true;
                    }
                    let pt = { 'x': d[0].trim(), 'y': d[5].trim() };
                    block.Point.push(pt);
                }
                else if (index === length - 1) {
                    if (block.Point.length === 1) {
                        let pt = { 'x': '-10', 'y': block.Point[0].y };
                        block.Point.unshift(pt);
                    }
                    else if (block.Point.length > 1) {
                        console.log('here::::');
                        // let neg = false;
                        if (block.Point[0].x !== '0') {

                            let newPoint = block.Point.map((p) => {
                                if (p.x === '0') {
                                    block.neg = false;
                                }
                                if (block.neg) {
                                    return { 'x': '-' + p.x, 'y': p.y };
                                }
                                else {
                                    return { 'x': p.x, 'y': p.y };
                                }
                            });
                            block.Point = newPoint;
                        }


                    }
                    data_array.push(block);
                }



      })
    }
    
    var excelsheetdata = $('#spreadsheet').jexcel('getData');
    arr=['STATION','BS','IS','FS','HI','RL','','REMARKS'];
    excelsheetdata.unshift(arr)

   // [excelsheetdata[3], excelsheetdata[4]] = [excelsheetdata[4], excelsheetdata[3]];
  
   // console.log("before",excelsheetdata);

      let fileData = '';
      let excelfiledata='';
      data_array.forEach(d => {
                fileData += 'GRO' + ' ' + d.Station;
                d.Point.forEach(p => {
                    fileData += ' ' + p.x + '/' + p.y;
                });
                fileData += '\n';

            });

            excelsheetdata.forEach(d => {
               
                d.forEach((p,index ) => {

                    if(index == 6  )
                    {
                       
                    }
                    else{
                        excelfiledata += ' ' + p+',';

                    }

                    
                });
                excelfiledata += '\n';

            });
          console.log("*********data",excelfiledata)

            var url = '{{ route("file-save") }}';
            $.ajax({
           type:'post',
           url:url,
           data:{
            excel:excelfiledata,
            data:fileData,
            id:id,
            _token: '{{csrf_token()}}'
           },
           success:function(data) {
           if(data== "create" )
           { console.log(data)
            Swal.fire(
            'created!',
            'sheet is created ',
            'success'
            ).then(function() {
                window.location = "/dashboard";
               
            });

           }else if(data == "updated"){
            console.log("iupdateddd",data)
            Swal.fire(
            'updated!',
            'sheet is updated ',
            'success'
            ).then(function() {
                window.location = "/dashboard";
                // window.history.back();
            });
           }
           else{
            Swal.fire(
            'some thing went wrong!',
            'refresh ',
            'error'
            )
           }
           }
        });


         });

        $("#btnDownload").click(function () {
        var data = $('#spreadsheet').jexcel('getData');
        console.log(data);
        let stringdata=JSON.stringify(data);

        var url = '{{ route("import-data") }}';
        $.ajax({
           type:'post',
           url:url,
           data:{
            data:stringdata,
            id:id,
            _token: '{{csrf_token()}}'
           },
           success:function(data) {
            Swal.fire(
            'Updated!',
            'Sheet is updated ',
            'success'
            ).then(function() {
                window.location = "/dashboard";
                // window.history.back();
            });


           }
        });
    });

    document.getElementById('download').onclick = function () {
        table.download();
        //table.render(document.getElementById('spreadsheet'));
    }

        }



